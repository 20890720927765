.Triangle {
    margin-top: -0.75rem;
    border-top: 0px;
    border-left: 20px solid transparent !important;
    border-right: 20px solid;
    border-bottom: 20px solid transparent !important;
    height: 0;
    width: 0;
    position: absolute;
    right: 0px;
    z-index: 2;
  }
